import { graphql } from "gatsby";
import { GatsbyImage, getImage, getSrc, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { useTranslation } from "react-i18next";
import { AboutSection } from "../components/molecules/AboutSection/AboutSection";
import { BlogPostPreview } from "../components/molecules/BlogPostPreview/BlogPostPreview";
import { Footer } from "../components/molecules/Footer/Footer";
import { NavigationBar } from "../components/molecules/NavigationBar/NavigationBar";
import { SEO } from "../components/molecules/SEO/SEO";
import { GalahRoute } from "../services/Constants/GalahRoute";

const Blogs = ({
    data: {
        allMarkdownRemark: { edges },
    },
}) => {
    const [t] = useTranslation();
    const title = t("blogIndexPage.title");
    const description = t("thegalahBlogs");
    const firstPost = edges[0].node;
    const image = getImage(firstPost?.frontmatter.featuredImage) as IGatsbyImageData;
    const featuredImageSrc = `https://${process.env.GATSBY_HOST}${getSrc(image)}`;

    const posts = edges.map((edge) => {
        const image = getImage(edge.node.frontmatter.featuredImage) as IGatsbyImageData;
        const imageAlt = edge.node.frontmatter.title;
        return (
            <BlogPostPreview key={edge.node.id} {...edge.node}>
                <GatsbyImage
                    image={image}
                    alt={imageAlt}
                    className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
            </BlogPostPreview>
        );
    });

    return (
        <>
            <SEO title={title} description={description} relativeCanonicalUrl={GalahRoute.Home} socialImage={featuredImageSrc} />
            <NavigationBar />
            <AboutSection />

            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:max-w-4xl">
                        <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">{posts}</div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default Blogs;

export const pageQuery = graphql`
    query BlogQuery {
        allMarkdownRemark(
            sort: { frontmatter: { date: DESC } }
            filter: { fileAbsolutePath: { regex: "/(src/source-filesystem/markdown-pages)/" } }
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    timeToRead
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        slug
                        title
                        description
                        author
                        tag
                        featuredImage {
                            childImageSharp {
                                gatsbyImageData(width: 800, height: 400, placeholder: BLURRED, transformOptions: { fit: COVER })
                            }
                        }
                    }
                }
            }
        }
    }
`;
